import React from 'react';
import Login from 'components/Login';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';
import { GetStaticProps } from 'next';
import { NextSeo } from 'next-seo';
import getStaticPathsSite from 'utils/getStaticPathsSite';

export default function LoginPage() {
  const { site } = useInstanceConfig();
  const title = 'Login';
  const url = `https://${site.host}/login`;
  const { siteName, homepage } = site.seo;
  const description = `Login Page for ${siteName}`;

  return (
    <>
      <Login />
      <NextSeo
        canonical={url}
        description={description}
        openGraph={{
          title,
          description,
          site_name: siteName,
          type: 'website',
        }}
        title={title}
        titleTemplate={`%s | ${homepage.title}`}
      />
    </>
  );
}

export { getStaticPathsSite as getStaticPaths };

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
    revalidate: 180,
  };
};
