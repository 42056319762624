import React from 'react';
import Form from 'components/Form';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';
import { FetchingStatus } from 'types/enums/FetchType';

import { Button, Typography } from '@lyearn/ui';

import FormHeader from '../FormHeader';
import useLoginForm from './hooks/useLoginForm';

const LoginForm = (props: { switchToSignup?: Function; redirectPath?: string }) => {
  const { switchToSignup = () => {}, redirectPath = '/' } = props;
  const { site } = useInstanceConfig();
  const {
    initialValues,
    formFields,
    formSubmissible,
    onFormChange,
    validate,
    loginStatus,
    formikSubmit,
    setFormikSubmit,
    onSubmit,
  } = useLoginForm({
    redirectPath,
  });

  return (
    <div className="login-container mt-20 mb-50 flex flex-shrink-0 flex-col items-center">
      <FormHeader switchToSignup={switchToSignup} />
      <Form
        fields={formFields}
        formName="Log In"
        initialValues={initialValues}
        layout={['email', 'password']}
        setSubmitRef={setFormikSubmit}
        validate={validate}
        onChange={onFormChange}
        onSubmit={onSubmit}
      />
      <Button
        className="my-10 mt-32 w-full normal-case"
        disabled={!formSubmissible || loginStatus === FetchingStatus.Fetching}
        loading={loginStatus === FetchingStatus.Fetching}
        size="large"
        variant="filled"
        onClick={formikSubmit as any}>
        <Typography variant="heading-xs">Login to {site.displayCompanyName}</Typography>
      </Button>
    </div>
  );
};

export default LoginForm;
