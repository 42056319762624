import { useState } from 'react';
import request from 'helpers/request';
import { useUser } from 'modules/context/Contexts/User';
import { useRouter } from 'next/router';
import { FetchingStatus } from 'types/enums/FetchType';
import sanitizeURL from 'utils/sanitizeUrl';

import { setLoginCookies } from '../utils/setLoginCookies';

const useLogin = () => {
  const router = useRouter();
  const user = useUser();

  const [loginStatus, setLoginStatus] = useState(FetchingStatus.NotStarted);
  const handleLogin = async (data: any, redirectPath?: string) => {
    setLoginStatus(FetchingStatus.Fetching);
    const response = await request('/api/auth/login/emailAuthenticator', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'content-type': 'application/json; charset=utf-8',
      },
    });

    const responseData = await response.json();

    if (responseData.success) {
      setLoginCookies(responseData?.loginToken);
      await user.refreshUser();

      setLoginStatus(FetchingStatus.Done);

      if (redirectPath) {
        (window as any).sanitizeURL = sanitizeURL;
        const url = sanitizeURL(redirectPath);

        return router.push(url);
      }

      return null;
    } else {
      setLoginStatus(FetchingStatus.Error);
      return responseData;
    }
  };

  return { loginStatus, handleLogin };
};
export default useLogin;
