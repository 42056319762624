import React, { useState } from 'react';
import Form, { FieldType } from 'components/Form';
import { FormikValues } from 'formik';
import useFormikSubmit from 'hooks/useFormikSubmit';
import useLogin from 'modules/auth/hooks/useLogin';
import useLookupEmail from 'modules/auth/hooks/useLookupEmail';
import Link from 'next/link';
import AuthErrors from 'types/enums/AuthErrors';
import isEmail from 'validator/lib/isEmail';

import { Typography } from '@lyearn/ui';

const initialValues = {
  email: '',
  password: '',
};

const formFields = {
  email: {
    name: 'email',
    placeholder: 'Enter your email',
    label: 'Email',
    type: FieldType.Email,
  },
  password: {
    name: 'password',
    placeholder: 'Enter your password',
    label: (
      <div className="mb-6 flex w-full items-center justify-between me-12">
        <Typography className="text-text-primary" variant="body-short-02">
          Password
        </Typography>
        <Link href="/forgot-password">
          <Typography
            className="cursor-pointer text-text-primary underline"
            variant="body-short-03">
            Forgot Password?
          </Typography>
        </Link>
      </div>
    ),
    type: FieldType.Password,
  },
} as const;

const validate = (values: FormikValues) => {
  const errors: any = {};
  if (!values.email) {
    errors.email = 'Please enter your email id.';
  }
  if (values.email && !isEmail(values.email)) {
    errors.email = 'Please enter your correct email id.';
  }
  if (!values.password) {
    errors.password = 'Please enter your password.';
  }
  return errors;
};

const useLoginForm = ({ redirectPath }: { redirectPath: string }) => {
  const { loginStatus, handleLogin } = useLogin();
  const [handleLookupEmail] = useLookupEmail();
  const [formikSubmit, setFormikSubmit] = useFormikSubmit();
  const [submissible, setSubmissible] = useState(false);

  const onSubmit = async (values: TSFixMe, formHelpers: TSFixMe) => {
    const { password, email: emailId } = values;
    const { setFieldError } = formHelpers;
    const emailError = await (handleLookupEmail as any)({ emailId });
    if (emailError && emailError.errorCode !== AuthErrors.EMAIL_NOT_VERIFIED) {
      if (
        emailError.errorCode === AuthErrors.ACCOUNT_NOT_FOUND ||
        emailError.errorCode === AuthErrors.INVALID_DATA
      ) {
        setFieldError(
          'email',
          <span>
            Email not registered with us. Try{' '}
            <Link className="text-underline" href="/signup">
              creating a new account.
            </Link>
          </span>,
        );
      } else if (emailError.errorCode === AuthErrors.PASSWORD_NOT_SET) {
        setFieldError('password', <span>Password has not been set. Try setting a password?</span>);
      }
    } else {
      const loginError = await (handleLogin as any)({ emailId, password }, redirectPath);
      if (loginError) {
        if (loginError.errorCode === AuthErrors.INCORRECT_PASSWORD) {
          setFieldError('password', <span>Please enter your correct password.</span>);
        }
      }
    }
  };

  const onFormChange = ({ values, isValid }: TSFixMe) => {
    if (values.email && values.password && isValid) {
      setSubmissible(true);
    } else {
      setSubmissible(false);
    }
  };
  return {
    onFormChange,
    formSubmissible: submissible,
    formikSubmit,
    setFormikSubmit,
    onSubmit,
    validate,
    initialValues,
    formFields,
    loginStatus,
  };
};

export default useLoginForm;
