import React from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';

import Header from '../Header';
import LoginForm from './components/LoginForm';
import { loginRoot } from './styles';

function Login() {
  const router = useRouter();
  const { redirectPath } = router.query;

  return (
    <div
      className={cn(
        loginRoot,
        'relative flex h-full w-full max-w-full flex-col items-center overflow-auto',
      )}>
      <Header
        LeftNodeRef="@lyearn/components/Header/BrandInfo"
        RightNode={null}
        RightNodeRef={null}
        centered={true}
        headerStyles={{
          solidBackgroundColor: 'rgba(255, 255, 255, 0.96)',
        }}
        headerType="sticky"
        height="8rem"
        leftNodeProps={{
          isHomepage: true,
        }}
        middleNodeAlign="center"
      />
      <LoginForm
        redirectPath={redirectPath as string}
        switchToSignup={() =>
          router.push('/signup?redirectPath=' + encodeURIComponent((redirectPath as string) || ''))
        }
      />
    </div>
  );
}

export default Login;
