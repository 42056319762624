import request from 'helpers/request';

const useLookupEmail = () => {
  const handleLookupEmail = async (data: any) => {
    const response = await request('/api/auth/accountLookup', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'content-type': 'application/json; charset=utf-8',
      },
    });
    const responseData = await response.json();
    if (responseData.success) {
      return null;
    } else {
      return responseData;
    }
  };

  return [handleLookupEmail];
};
export default useLookupEmail;
